import React from "react";
import TimManajemen from './TimManajemen'
import TimSupport from "./TimSupport";
const About = () => {
  return (
    <>
      <div className="h-[50vh] lg:h-screen relative" id="about">
        <section className="bg-about h-[50vh] lg:h-screen lg:bg-cover bg-center" style={{backgroundAttachment: 'fixed'}}>
          <div className="absolute inset-0  bg-black bg-opacity-80"></div>
          <div className="container mx-auto">
          <div className="absolute inset-0 flex flex-col items-center justify-center">
            <div className="font-primary text-xl lg:text-2xl text-center text-gradient">
              <h1 >
                Tentang PT.SSR Digital Informatika IT Solution.
              </h1>
            </div>
            <div className="mt-2 font-primary font-bold lg:font-extrabold text-2xl lg:text-[60px] text-center text-white">
              <h1 className="py-2 lg:py-14">Kami adalah Perusahaan</h1>
              <h1>Digital IT Solution</h1>
            </div>
          </div>
          </div>
        </section>
      </div>
      <div className="container mx-auto mb-10">
        <div className="pt-8 px-4 grid lg:grid-cols-2 gap-10">
          <div> 
          <h1 className="font-primary font-bold">Tentang Kami:</h1> 
          <p>
          PT. SSR DIGITAL INFORMATIKA berdiri pada tahun 2021. yaitu sebuah perusahaan yang bergerak dalam bidang Teknologi Informatika yang berfokus pada pengembangan Sistem Internet jaringan Dan Pemograman.
          Inovasi kami mencakup pengembangan produk, proses, atau model bisnis baru yang lebih sesuai dengan kebutuhan sekelompok konsumen Dan berupaya menciptakan program – program yang unggul agar bisa suatu karya bermanfaat bagi semua kalangan.
          SSR Digital Informatika Mempunyai TIM yang Solid dan memiliki Spesialis dibidang Jaringan, Pemograman dan Aplikasi, Sehingga mampu Menciptakan Karya-karya Aktual bagi Mitra IT
            </p> 
          </div>
          <div>
            <h1 className="font-primary font-bold">Misi Perusahaan:</h1>
            <p>Mengembangkan Product yang berkualitas dan Kompetitif</p>
            <ul className="list-disc pl-4">
              <li>Memberikan servis yang profesional kepada mitra Mengembangkan kemitraan yang saling menguntungkan Mengembangkan inovasi terbaik dan terkini dalam setia Meningkatkan benefit dan nilai tambah bagi mitra</li>
              <li>Mengembangkan kemitraan yang saling menguntungkan Mengembangkan inovasi terbaik dan terkini dalam setia Meningkatkan benefit dan nilai tambah bagi mitra</li>
              <li>Mengembangkan inovasi terbaik dan terkini dalam setia</li>
              <li>Meningkatkan benefit dan nilai tambah bagi mitra</li>
            </ul>
          </div>
        </div>
      </div>
     <TimManajemen />
     <TimSupport/>
    </>
  );
};

export default About;
