import React from "react";
import LogoRadboox from "../assets/logo-radboox.png";
import RadbooxNet from "../assets/radboxnet.png";
import ItConsultant from "../assets/it-consultant.jpg";
import DataCenter from "../assets/datacenter.jpg";
import AppDevelopment from "../assets/app-development.png";
import CCTV from "../assets/cctv.png";
import { motion } from "framer-motion";
import { fadeIn } from "../variants";

const Services = () => {
  return (
    <>
      <div
        className="h-[50vh] lg:h-screen relative overflow-hidden"
        id="services"
      >
        <section
          className="bg-services h-[50vh] lg:h-screen bg-cover lg:bg-cover lg:bg-center"
          style={{ backgroundAttachment: "fixed" }}
        >
          <div className="absolute inset-0  bg-black bg-opacity-70"></div>
          <div className="container mx-auto">
            <div className="absolute inset-0 flex flex-col items-center justify-center">
              <div className="mt-2 font-primary font-bold lg:font-extrabold text-2xl lg:text-[60px] text-center text-white">
                <h1 className="py-2 lg:py-14">Layanan Unggulan Kami</h1>
              </div>
              <div className="container text-sm lg:text-xl px-8 lg:px-32 text-center text-white">
                <p>
                  Kami telah menyediakan berbagai layanan IT digital yang dapat
                  membantu meningkatkan kinerja bisnis Anda. Dari Networking,
                  Internet Provider serta pengembangan aplikasi web dan mobile.
                  Kami memiliki solusi yang tepat untuk kebutuhan digital bisnis
                  Anda. Mari bergabung bersama kami dan tingkatkan kinerja
                  digital bisnis Anda sekarang
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div className="py-10 container mx-auto px-2">
        <div className="grid gri-cols-1 lg:grid-cols-3 sm:gap-20 gap-20 justify-center items-center">
          <motion.div
            variants={fadeIn("right", 0.3)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.1 }}
            className="bg-[#0468A6] h-[400px] rounded-3xl shadow-md shadow-gray-800 p-2 flex flex-col justify-center items-center"
          >
            <img
              className="lg:mt-4 mt-2 shadow-md shadow-gray-900 rounded-full w-[100px]"
              src={LogoRadboox}
              alt=""
            />

            <h1 className="mt-2 text-2xl font-extrabold text-white border-b-2 border-gray-100">
              My Radboox
            </h1>

            <p className="mt-4 text-[#E69519] font-primary font-bold text-center leading-6">
              Solusi Akuntansi <br />
              Dan Otentikasi MikroTik Anda
            </p>
            <p className="text-white text-center text-sm px-8 py-2">
              Radius Server, Users Management, Billing, Notification, Payment
              Gateway, OLT ZTE Management Semua dalam satu Applikasi
            </p>
            <a href="https://radboox.com">
              <button className="lg:mb-4 mb-2 mt-4 text-center border border-white btn btn-me">
                Lihat Detail
              </button>
            </a>
          </motion.div>
          <motion.div
            variants={fadeIn("down", 0.3)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.2 }}
            className="bg-[#0468A6] h-[450px] rounded-3xl shadow-md shadow-gray-800 p-2 flex flex-col justify-center items-center"
          >
            <img
              className="shadow-md shadow-gray-900 w-[60%] rounded-2xl"
              src={RadbooxNet}
              alt=""
            />

            <h1 className="mt-4 text-2xl font-extrabold text-white border-b-2 border-gray-100">
              Radboox Net
            </h1>

            <p className="mt-4 text-[#E69519] font-primary font-bold text-center leading-6">
              ISP Provider <br /> The Next Your Internet Provider
            </p>
            <p className="text-white text-center text-sm px-8 py-2">
              Paket Internet yang cepat, jangkuan jaringan yang luas, Stabil dan
              Unlimited Kepuasan Pelanggan akan kinerja kami itulah priotitas
              kami.
            </p>
            <a href="http://radboox.net">
              <button className="lg:mb-4 mb-2 mt-4 text-center border border-white btn btn-me">
                Lihat Detail
              </button>
            </a>
          </motion.div>
          <motion.div
            variants={fadeIn("left", 0.3)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.3 }}
            className="bg-[#0468A6] h-[400px] rounded-3xl shadow-md shadow-gray-800 p-2 flex flex-col justify-center items-center"
          >
            <img
              className="lg:mt-4 mt-2 shadow-md shadow-gray-900 rounded-full w-[100px]"
              src={ItConsultant}
              alt=""
            />

            <h1 className="mt-2 text-2xl font-extrabold text-white border-b-2 border-gray-100">
              It Consultant
            </h1>

            <p className="mt-4 text-[#E69519] font-primary font-bold text-center leading-6">
              Solusi It Media Business Anda <br />
            </p>
            <p className="text-white text-center text-sm px-8 py-2">
              Kami bantu mengidentifikasi, merencanakan, mengimplementasikan,
              dan mengelola solusi teknologi yang sesuai dengan kebutuhan bisnis
              dan tujuan Anda
            </p>
            <a href="https://wa.me/6282116458866?text=Halo,%20saya%20mau%20info%20lebih%20lanjut%20tentang%20ssrdi">
              <button className="lg:mb-4 mb-2 mt-4 text-center border border-white btn btn-me">
                Kontak Kami
              </button>
            </a>
          </motion.div>
        </div>
        <div className="grid gri-cols-1 lg:grid-cols-3 sm:gap-20 gap-20 mt-10 justify-center items-center">
          <motion.div
            variants={fadeIn("right", 0.3)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.1 }}
            className="bg-[#0468A6] h-[400px] rounded-3xl shadow-md shadow-gray-800 p-2 flex flex-col justify-center items-center"
          >
            <img
              className="lg:mt-4 mt-2 shadow-md shadow-gray-900 rounded-full w-[100px]"
              src={AppDevelopment}
              alt=""
            />

            <h1 className="mt-2 text-2xl font-extrabold text-white border-b-2 border-gray-100">
              App Development
            </h1>

            <p className="mt-4 text-[#E69519] font-primary font-bold text-center leading-6">
              Pengembangan Web dan Aplikasi <br />
            </p>
            <p className="text-white text-center text-sm px-8 py-2">
              membantu mewujudkan perkembangan bisnis anda dengan
              mengaplikasikan bisnis anda menjadi sebuah web atau aplikasi
              digital
            </p>
            <a href="https://wa.me/6282116458866?text=Halo,%20saya%20mau%20info%20lebih%20lanjut%20tentang%20ssrdi">
              <button className="lg:mb-4 mb-2 mt-4 text-center border border-white btn btn-me">
                Kontak Kami
              </button>
            </a>
          </motion.div>
          <motion.div
            variants={fadeIn("down", 0.3)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.2 }}
            className="bg-[#0468A6] h-[450px] rounded-3xl shadow-md shadow-gray-800 p-2 flex flex-col justify-center items-center"
          >
            <img
              className="shadow-md shadow-gray-900 w-[60%] rounded-2xl"
              src={DataCenter}
              alt=""
            />

            <h1 className="mt-4 text-2xl font-extrabold text-white border-b-2 border-gray-100">
              Data Center
            </h1>

            <p className="mt-4 text-[#E69519] font-primary font-bold text-center leading-6">
              Simpan Data Digital Anda <br /> Ditempat yang Aman
            </p>
            <p className="text-white text-center text-sm px-8 py-2">
              Penyediaan dan manajemen handal yang dirancang khusus untuk
              menyimpan, mengelola, dan menjalankan infrastruktur teknologi
              informasi (TI) untuk perusahaan atau organisasi maupun individu.
            </p>
            <a href="https://wa.me/6282116458866?text=Halo,%20saya%20mau%20info%20lebih%20lanjut%20tentang%20ssrdi">
              <button className="lg:mb-4 mb-2 mt-4 text-center border border-white btn btn-me">
                Kontak Kami
              </button>
            </a>
          </motion.div>
          <motion.div
            variants={fadeIn("left", 0.3)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.3 }}
            className="bg-[#0468A6] h-[400px] rounded-3xl shadow-md shadow-gray-800 p-2 flex flex-col justify-center items-center"
          >
            <img
              className="lg:mt-4 mt-2 shadow-md shadow-gray-900 rounded-full w-[100px]"
              src={CCTV}
              alt=""
            />

            <h1 className="mt-2 text-2xl font-extrabold text-white border-b-2 border-gray-100">
              IP CCTV
            </h1>

            <p className="mt-4 text-[#E69519] font-primary font-bold text-center leading-6">
              Solusi Keamanan Rumah <br /> dan Bisnis Anda
            </p>
            <p className="text-white text-center text-sm px-8 py-2">
              Kami bantu instalasi kemananan anda melaui CCTV dengan
              professional
            </p>
            <a href="https://wa.me/6282116458866?text=Halo,%20saya%20mau%20info%20lebih%20lanjut%20tentang%20ssrdi">
              <button className="lg:mb-4 mb-2 mt-4 text-center border border-white btn btn-me">
                Kontak Kami
              </button>
            </a>
          </motion.div>
        </div>
      </div>
    </>
  );
};

export default Services;
