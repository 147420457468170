import React from "react";
import Logo from "../assets/logo-dark.png";

const Header = () => {
  return (
    <header>
      <div class="fixed w-full bg-[#045C94] pb-1 shadow-md z-10"> 
      <div className="container h-[70px] mx-auto ">               
        <div className="flex justify-between items-center">
          <a href="/">
            <img className="w-[150px]" src={Logo} alt="" />
          </a> 
          <a href="https://wa.me/6282116458866?text=Halo,%20saya%20mau%20info%20lebih%20lanjut%20tentang%20ssrdi">
            <button className="btn shadow-sm btn-sm border text-white duration-100 ease-in-out border-gray-200">Contact Us</button></a>
        </div>
        </div>
      </div>      
    </header>
  );
};

export default Header;
