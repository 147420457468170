import React from 'react';
import {Link} from 'react-scroll';
import {BiHomeAlt, BiUser} from 'react-icons/bi';
import {RiSpeakFill} from 'react-icons/ri';
import {FaServicestack} from 'react-icons/fa';
import {BsChatSquare} from 'react-icons/bs';
import {FaUsers} from 'react-icons/fa'

const Nav = () => {
  return <nav className='fixed bottom-4 lg:bottom-6 w-full overflow-hidden z-50'>
    <div className='container mx-auto'>
      <div className='w-full h-[76px] bg-[#045C94] backdrop-blur-2xl border border-white rounded-full max-w-[460px] mx-auto md:px-5 px-5 flex items-center justify-between text-2xl text-white/50'>
        <Link to='home' activeClass='active' smooth={true} spy={true} className='cursor-pointer md:w-[40px] md:h-[40px] w-[40px] h-[40px] flex items-center justify-center'>
          <BiHomeAlt/>
        </Link>
        <Link to='about' activeClass='active' smooth={true} spy={true} className='cursor-pointer w-[40px] h-[40px] flex items-center justify-center'>
          <BiUser/>
        </Link>
        <Link to='services' activeClass='active' smooth={true} spy={true} className='cursor-pointer w-[40px] h-[40px] flex items-center justify-center'>
          <FaServicestack className="text-white"/>
        </Link>
        <Link to='testimoni' activeClass='active' smooth={true} spy={true} className='cursor-pointer w-[40px] h-[40px] flex items-center justify-center'>
          <RiSpeakFill/>
        </Link>
        <Link to='partner' activeClass='active' smooth={true} spy={true} className='cursor-pointer w-[40px] h-[40px] flex items-center justify-center'>
          <FaUsers/>
        </Link>
        <Link to='contact' activeClass='active' smooth={true} spy={true} className='cursor-pointer w-[40px] h-[40px] flex items-center justify-center'>
          <BsChatSquare/>
        </Link>
      </div>
    </div>
  </nav>;
};

export default Nav;
