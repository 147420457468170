import React from "react";
import LOGOSSR from "../assets/logo-ssr.png";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { TypeAnimation } from "react-type-animation";
import { motion } from "framer-motion";
import { fadeIn} from "../variants";

const Banner = () => {
  return (
    <section className="w-full min-h-[85vh] lg:min-h-[78vh] flex items-center bg-[#0468A6] section " id="home">
      <div className="container mx-auto pt-16 ">
        <div className="flex flex-col gap-y-8 lg:flex-row lg:items-center lg:gap-x-12">
          <div className="flex-1 text-center lg:text-left">
            <div className="lg:mb-4">
              <motion.h1 
              variants={fadeIn('up',0.3)}
              initial = 'hidden'
              whileInView= {'show'}
              viewport ={{once:false, amount:0.7}}
              className="text-white font-secondary text-[1.2rem] mt-4 lg:text-[2rem]">
                PT. SSR Digital Informatika
              </motion.h1>
            </div>
            <motion.div 
            variants={fadeIn('up',0.4)}
            initial = 'hidden'
            whileInView= {'show'}
            viewport ={{once:false, amount:0.7}}
            className="mr-4 font-extrabold mb-6 text-[1.8rem] lg:text-[3rem]">
              <TypeAnimation
                sequence={[
                  "DIGITAL IT SOLUTION ",
                  2000,
                  "SOLUSI LAYANAN DIGITAL ",
                  2000,
                  "ADVANCED & RELIABLE ",
                  2000,
                ]}
                speed={50}
                className="text-[#E69519]"
                wrapper="span"
                repeat={Infinity}
              />
            </motion.div>
            <motion.div 
            variants={fadeIn('up',0.5)}
            initial = 'hidden'
            whileInView= {'show'}
            viewport ={{once:false, amount:0.7}}
            className="text-white text-[0.8rem] flex flex-col justify-center items-center lg:items-start leading-[1.2rem] lg:text-2xl">
              <div className="flex justify-start gap-x-2 items-center">
                <BsFillCheckCircleFill className="text-black"/>{" "}
                <div>Solusi Layanan Digital Terpercaya</div>
              </div>
              <div className="flex justify-start gap-x-2 items-center">
                <BsFillCheckCircleFill className="text-black"/>{" "}
                <div>
                  Team yang sudah berpengalaman di bidangnya.
                </div>
              </div>
              <div className="flex justify-start gap-x-2 items-center">
                <BsFillCheckCircleFill className="text-black"/>{" "}
                <div>
                  Kepuasan Pelanggan adalah Amanah Kami
                </div>
              </div>
            </motion.div>   
            <motion.div 
            variants={fadeIn('up',0.6)}
            initial = 'hidden'
            whileInView= {'show'}
            viewport ={{once:false, amount:0.7}}
            className="mt-[2rem] flex justify-center gap-x-6 items-center lg:items-start lg:justify-start">
              <a href="https://wa.me/6282116458866?text=Halo,%20saya%20mau%20info%20lebih%20lanjut%20tentang%20ssrdi"><button className="btn btn-lg border text-xl border-white duration-100 ease-in-out">Contact Us</button></a>
              
            </motion.div>
          </div>
          

          <motion.div 
          variants={fadeIn('down',0.5)}
          initial = 'hidden'
          whileInView= {'show'}
          viewport ={{once:false, amount:0.7}}
          className="flex justify-center items-center">
            <img src={LOGOSSR} alt="logo" className="w-[300px] lg:w-[400px]"/>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
