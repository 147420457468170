import React from "react";
import { BsWhatsapp } from "react-icons/bs";
import { AiOutlineMail } from "react-icons/ai";
import { ImLocation2 } from "react-icons/im";

const Contact = () => {
  return (
    <section
      className="w-full min-h-[85vh] lg:min-h-[58vh] flex items-center "
      id="contact"
    >
      <div className="container mx-auto py-6 px-2">
        <div className="flex flex-col justify-center items-center">
          <h1 className="text-3xl items-center font-extrabold text-[#E69519] border-b-2 border-gray-900">
            Contact Us
          </h1>
        </div>
        <div className="pt-8 grid lg:grid-cols-3 grid-cols-1 gap-10">
          <div className="bg-[#0468A6] rounded-3xl flex p-6 flex-col justify-start items-center">
            <div className="rounded-full bg-[#6CAC16] w-[40px] h-[40px] justify-center items-center flex p-2">
              <BsWhatsapp className="text-white" />
            </div>
            <p className="text-2xl mt-4 text-white ">6282116458866</p>
            <a href="https://wa.me/6282116458866?text=Halo,%20saya%20mau%20info%20lebih%20lanjut%20tentang%20ssrdi">
              <button className="mt-4 btn btn-sm border border-white">
                Hubungi Kami
              </button>
            </a>
          </div>
          <div className="bg-[#0468A6] rounded-3xl flex p-6 flex-col justify-start items-center">
            <div className="rounded-full bg-[#6CAC16] w-[40px] h-[40px] justify-center items-center flex p-2">
              <AiOutlineMail className="text-white" />
            </div>
            <p className="text-2xl mt-4 text-white ">admin@ssrdi.co.id</p>
            <a href="mailto:admin@ssrdi.co.id">
              <button className="mt-4 btn btn-sm border border-white">
                Kirim Email
              </button>
            </a>
          </div>
          <div className="bg-[#0468A6] rounded-3xl flex p-6 flex-col justify-start items-center">
            <div className="rounded-full bg-[#6CAC16] w-[40px] h-[40px] justify-center items-center flex p-2">
              <ImLocation2 className="text-white" />
            </div>
            <p className="text-2xl mt-4 text-white ">Head Office :</p>
            <p className="text-[1rem] mt-4 text-white text-center">
              Jl. Raya Karangampel, Desa Dukuh Jeruk, Kec. Karangampel, Kab.
              Indramayu, Jawa Barat
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
