import React from "react";
import Logo from "../assets/logo-dark.png";
import { BsFacebook, BsYoutube, BsInstagram } from 'react-icons/bs';

const Header = () => {
  return (
    <>
    
      <section class="w-full bg-[#045C94] "> 
      <div className="container mx-auto ">               
        <div className="grid py-4 lg:grid-cols-3 grid-cols-1">
          <div className="py-2 px-4">
            <a href="/">
            <img className="w-[150px]" src={Logo} alt="" />
            <p className="text-sm text-white">
            PT. SSR DIGITAL INFORMATIKA sebuah perusahaan yang bergerak dalam bidang Teknologi Informatika yang berfokus pada pengembangan Sistem Internet jaringan Dan Pemograman
            </p>
          </a> 
          </div>
          <div className="py-2 px-4">
          <h1 className="text-xl font-bold text-white">Follow Sosial Media Kami :</h1>
          <div className="flex mt-4 justify-start gap-6 items-center text-white">
            <BsFacebook/> <BsYoutube/> <BsInstagram/>
          </div>
          </div>
          
          <div className="py-2 px-4 z-100">
            <h1 className="text-xl font-bold text-white">Head Office :</h1>
            <p className="text-sm text-white">
            Jl. Raya Karangampel, Desa Dukuh Jeruk, Kec. Karangampel, Kab. Indramayu, Jawa Barat
            </p>
          </div>         
          
        </div>
        </div>
      </section>      
 
     <div class="w-full bg-[#1c4f6e] p-2 shadow-md"> 
     <div className="container mx-auto text-right text-white">  
     Copyright @ssrdi.co.id  
     </div>
     </div> 
     </>
  );
};

export default Header;
