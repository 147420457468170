import React, { useState, useEffect, useRef } from "react";
import DefaultPic from "../assets/noupload.png";
import Wahyudi from "../assets/team/wahyudi.jpg";
import Bahri from "../assets/team/bahri.jpg";
import Wendi from "../assets/team/wendi.jpg";
import Stanly from "../assets/team/stanly.jpg";
import Rahmat from "../assets/team/rahmat.jpg";
import Munajat from "../assets/team/munajat.jpg";
import Caman from "../assets/team/caman.jpeg";
import AdiHidayat from "../assets/team/adihidayat.jpeg";
import DediSanjaya from "../assets/team/dedisanjaya.jpg"
import NewTim from "../assets/team/newtim.jpg"
import RahmatSinggih from "../assets/team/rahmat-singgih.jpg"
import PutraPratama from "../assets/team/putra-pratama.jpg"
import Rizki from "../assets/team/rizki.jpg"
import Ab from "../assets/team/ab.jpg"
import Faisal from "../assets/team/faisal.jpg"
import Oji from "../assets/team/oji.jpg"
import Joko from "../assets/team/joko.jpg"
// import Carousel from "better-react-carousel"
import Slide from "react-responsive-slide";

// import './timsupport.css'

import { motion } from "framer-motion";
import { fadeIn } from "../variants";
import { FaGithub, FaYoutube, FaWhatsapp } from "react-icons/fa";

const TimSupport = () => {
  const [isMobile, setIsMobile] = useState(false);
  const containerRef = useRef()

  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 768px)');
    const handleMediaQueryChange = (e) => {
      setIsMobile(e.matches);
    };

    setIsMobile(mediaQuery.matches);

    mediaQuery.addEventListener('change', handleMediaQueryChange);

    return () => {
      mediaQuery.removeEventListener('change', handleMediaQueryChange);
    };
  }, []);


  return (
    <>
      <div className="w-full">
        <div className="bg-[#0468A6]">
          <div className="container mx-auto pb-10 px-4">
            <div className="flex flex-col justify-center items-center py-6">
              <h1 className="text-[#E69519] text-2xl font-bold">
                Tim Support{" "}
              </h1>
              <h1 className="text-white text-center text-2xl mb-5 lg:mb-10 lg:text-4xl font-bold">
                PT.SSR Digital Informatika{" "}
              </h1>
            </div>
            {isMobile ? (
              <>
              <div
              ref={containerRef}
              style={{
                width: "90vw",
                margin: "auto",
                maxWidth: "1500px",
                minWidth: "350px",
              }}
            >
              <Slide 
              slideContainer={containerRef}
              pagination={false}
              autoSlideInterval={3000}
              defaultItemsPerPage={1}
              itemRatio="1/1"
              itemPaddingX={50}
              containerPaddingX={50}
            containerPaddingY={50}
            responsives={[
              { range: { from: null, to: 500 }, itemsPerPage: 1 },
              { range: { from: 501, to: 900 }, itemsPerPage: 1 },
              { range: { from: 901, to: 1300 }, itemsPerPage: 1 },
              { range: { from: 1301, to: null }, itemsPerPage: 1 },
            ]}
              
              >
              <img
                      className="border-2 border-black shadow-xs shadow-gray-900 rounded-full w-full lg:w-full"
                      src={Joko}
                      alt=""
                    />
                    <img
                      className="border-2 border-black shadow-xs shadow-gray-900 rounded-full w-full lg:w-full"
                      src={Wahyudi}
                      alt=""
                    />
                    <img
                      className="border-2 border-black shadow-xs shadow-gray-900 rounded-full w-full lg:w-full"
                      src={PutraPratama}
                      alt=""
                    />
                    <img
                      className="border-2 border-black shadow-xs shadow-gray-900 rounded-full w-full lg:w-full"
                      src={RahmatSinggih}
                      alt=""
                    />
                    <img
                      className="border-2 border-black shadow-xs shadow-gray-900 rounded-full w-full lg:w-full"
                      src={Rizki}
                      alt=""
                    />
                    <img
                      className="border-2 border-black shadow-xs shadow-gray-900 rounded-full w-full lg:w-full"
                      src={Faisal}
                      alt=""
                    />
                    <img
                      className="border-2 border-black shadow-xs shadow-gray-900 rounded-full w-full lg:w-full"
                      src={Rahmat}
                      alt=""
                    />
                    <img
                      className="border-2 border-black shadow-xs shadow-gray-900 rounded-full w-full lg:w-full"
                      src={Munajat}
                      alt=""
                    />
                    <img
                      className="border-2 border-black shadow-xs shadow-gray-900 rounded-full w-full lg:w-full"
                      src={Wendi}
                      alt=""
                    />
                    <img
                      className="border-2 border-black shadow-xs shadow-gray-900 rounded-full w-full lg:w-full"
                      src={Stanly}
                      alt=""
                    />
                    <img
                      className="border-2 border-black shadow-xs shadow-gray-900 rounded-full w-full lg:w-full"
                      src={DediSanjaya}
                      alt=""
                    />
                    <img
                      className="border-2 border-black shadow-xs shadow-gray-900 rounded-full w-full lg:w-full"
                      src={Oji}
                      alt=""
                    />
                    <img
                      className="border-2 border-black shadow-xs shadow-gray-900 rounded-full w-full lg:w-full"
                      src={Ab}
                      alt=""
                    />
                    <img
                      className="border-2 border-black shadow-xs shadow-gray-900 rounded-full w-full lg:w-full"
                      src={Caman}
                      alt=""
                    />
                    <img
                      className="border-2 border-black shadow-xs shadow-gray-900 rounded-full w-full lg:w-full"
                      src={AdiHidayat}
                      alt=""
                    />
                    <img
                      className="border-2 border-black shadow-xs shadow-gray-900 rounded-full w-full lg:w-full"
                      src={NewTim}
                      alt=""
                    />
                    <img
                      className="border-2 border-black shadow-xs shadow-gray-900 rounded-full w-full lg:w-full"
                      src={DefaultPic}
                      alt=""
                    />
                    <img
                      className="border-2 border-black shadow-xs shadow-gray-900 rounded-full w-full lg:w-full"
                      src={DefaultPic}
                      alt=""
                    />
              </Slide>
              </div>
                
              </>
            ) : (
              <>
                <div className="bg-[#1578b6] lg:h-[100%] rounded-3xl shadow-md shadow-gray-800 flex flex-col justify-center items-center">
                  < motion.div
                    variants={fadeIn("up", 0.3)}
                    initial="hidden"
                    whileInView={"show"}
                    viewport={{ once: false, amount: 0.3 }}
                    className="flex flex-row justify-between gap-1 items-center py-5">
                    {/* className="grid grid-cols-2 py-10 lg:grid-cols-13 gap-4 lg:gap-0  justify-center items-center"> */}

                    <img
                      className="border-2 border-black shadow-md shadow-gray-900 rounded-full w-[120px] lg:w-[60px]"
                      src={Joko}
                      alt=""
                    />
                    <img
                      className="border-2 border-black shadow-md shadow-gray-900 rounded-full w-[120px] lg:w-[60px]"
                      src={Wahyudi}
                      alt=""
                    />
                    <img
                      className="border-2 border-black shadow-md shadow-gray-900 rounded-full w-[120px] lg:w-[60px]"
                      src={PutraPratama}
                      alt=""
                    />
                    <img
                      className="border-2 border-black shadow-md shadow-gray-900 rounded-full w-[120px] lg:w-[60px]"
                      src={RahmatSinggih}
                      alt=""
                    />
                    <img
                      className="border-2 border-black shadow-md shadow-gray-900 rounded-full w-[120px] lg:w-[60px]"
                      src={Rizki}
                      alt=""
                    />
                    <img
                      className="border-2 border-black shadow-md shadow-gray-900 rounded-full w-[120px] lg:w-[60px]"
                      src={Faisal}
                      alt=""
                    />
                    <img
                      className="border-2 border-black shadow-md shadow-gray-900 rounded-full w-[120px] lg:w-[60px]"
                      src={Rahmat}
                      alt=""
                    />
                    <img
                      className="border-2 border-black shadow-md shadow-gray-900 rounded-full w-[120px] lg:w-[60px]"
                      src={Munajat}
                      alt=""
                    />
                    <img
                      className="border-2 border-black shadow-md shadow-gray-900 rounded-full w-[120px] lg:w-[60px]"
                      src={Wendi}
                      alt=""
                    />
                    <img
                      className="border-2 border-black shadow-md shadow-gray-900 rounded-full w-[120px] lg:w-[60px]"
                      src={Stanly}
                      alt=""
                    />
                    <img
                      className="border-2 border-black shadow-md shadow-gray-900 rounded-full w-[120px] lg:w-[60px]"
                      src={DediSanjaya}
                      alt=""
                    />
                    <img
                      className="border-2 border-black shadow-md shadow-gray-900 rounded-full w-[120px] lg:w-[60px]"
                      src={Oji}
                      alt=""
                    />
                    <img
                      className="border-2 border-black shadow-md shadow-gray-900 rounded-full w-[120px] lg:w-[60px]"
                      src={Ab}
                      alt=""
                    />
                    <img
                      className="border-2 border-black shadow-md shadow-gray-900 rounded-full w-[120px] lg:w-[60px]"
                      src={Caman}
                      alt=""
                    />
                    <img
                      className="border-2 border-black shadow-md shadow-gray-900 rounded-full w-[120px] lg:w-[60px]"
                      src={AdiHidayat}
                      alt=""
                    />
                    <img
                      className="border-2 border-black shadow-md shadow-gray-900 rounded-full w-[120px] lg:w-[60px]"
                      src={NewTim}
                      alt=""
                    />
                    <img
                      className="border-2 border-black shadow-md shadow-gray-900 rounded-full w-[120px] lg:w-[60px]"
                      src={DefaultPic}
                      alt=""
                    />
                    <img
                      className="border-2 border-black shadow-md shadow-gray-900 rounded-full w-[120px] lg:w-[60px]"
                      src={DefaultPic}
                      alt=""
                    />

                  </motion.div>
                </div>
              </>
            )}           
           

            {/* <div className="grid grid-cols-1 lg:grid-cols-4 gap-16 lg:gap-8 justify-center items-center">
              <motion.div
                variants={fadeIn("right", 0.3)}
                initial="hidden"
                whileInView={"show"}
                viewport={{ once: false, amount: 0.3 }}
                className="bg-[#1578b6] h-[320px] rounded-3xl shadow-md shadow-gray-800 p-2 flex flex-col justify-center items-center"
              >
                <img
                  className="lg:mt-[-175px] border-4 border-black mt-[-150px] shadow-md shadow-gray-900 rounded-full w-[120px] lg:w-[150px]"
                  src={DefaultPic}
                  alt=""
                />

                <h1 className="mt-2 tracking-wider uppercase text-2xl font-extrabold text-white border-b-2 border-gray-100">
                  Joko Susilo
                </h1>

                <p className="mt-4 text-[#E69519] font-primary font-extrabold text-center leading-6">
                  Staff Administrasi
                </p>
                <p className="text-white text-center text-sm px-8 py-2">
                  Staff Administrasi Management PT.SSRDI.
                </p>
                <div className="flex text-white justify-between space-x-4 items-center">
                  <FaYoutube className="w-[20px]" /> <FaGithub /> <FaWhatsapp />
                </div>
              </motion.div>
              <motion.div
                variants={fadeIn("down", 0.3)}
                initial="hidden"
                whileInView={"show"}
                viewport={{ once: false, amount: 0.1 }}
                className="bg-[#1578b6] lg:h-[320px] h-[250px] rounded-3xl shadow-md shadow-gray-800 p-2 flex flex-col justify-center items-center"
              >
                <img
                  className="lg:mt-[-150px] border-4 border-black mt-[-90px] shadow-md shadow-gray-900 rounded-full w-[120px] lg:w-[150px]"
                  src={Wahyudi}
                  alt=""
                />

                <h1 className="mt-2 tracking-wider uppercase text-2xl font-extrabold text-white border-b-2 border-gray-100">
                  Wahyudi
                </h1>

                <p className="mt-4 text-[#E69519] font-primary font-extrabold text-center leading-6">
                  Admin
                </p>
                <p className="text-white text-center text-sm px-8 py-2">
                  Admin PT. SSRDI
                </p>
                <div className="flex text-white justify-between space-x-4 items-center">
                  <FaYoutube className="w-[20px]" /> <FaGithub /> <FaWhatsapp />
                </div>
              </motion.div>
              <motion.div
                variants={fadeIn("down", 0.3)}
                initial="hidden"
                whileInView={"show"}
                viewport={{ once: false, amount: 0.2 }}
                className="bg-[#1578b6] h-[320px] rounded-3xl shadow-md shadow-gray-800 p-2 flex flex-col justify-center items-center"
              >
                <img
                  className="lg:mt-[-150px] border-4 border-black mt-[-150px] shadow-md shadow-gray-900 rounded-full w-[120px] lg:w-[150px]"
                  src={DefaultPic}
                  alt=""
                />

                <h1 className="mt-2 tracking-wider uppercase text-2xl font-extrabold text-white border-b-2 border-gray-100">
                  Putra Pratama
                </h1>

                <p className="mt-4 text-[#E69519] font-primary font-extrabold text-center leading-6">
                  It Support
                </p>
                <p className="text-white text-center text-sm px-8 py-2">
                  It Support PT.SSRDI.
                </p>
                <div className="flex text-white justify-between space-x-4 items-center">
                  <FaYoutube className="w-[20px]" /> <FaGithub /> <FaWhatsapp />
                </div>
              </motion.div>
              <motion.div
                variants={fadeIn("down", 0.3)}
                initial="hidden"
                whileInView={"show"}
                viewport={{ once: false, amount: 0.3 }}
                className="bg-[#1578b6] h-[320px] rounded-3xl shadow-md shadow-gray-800 p-2 flex flex-col justify-center items-center"
              >
                <img
                  className="lg:mt-[-150px] border-4 border-black mt-[-150px] shadow-md shadow-gray-900 rounded-full w-[120px] lg:w-[150px]"
                  src={DefaultPic}
                  alt=""
                />

                <h1 className="mt-2 tracking-wider uppercase text-2xl font-extrabold text-white border-b-2 border-gray-100">
                  Singgih Rahmat M
                </h1>

                <p className="mt-4 text-[#E69519] font-primary font-extrabold text-center leading-6">
                  It Supoort
                </p>
                <p className="text-white text-center text-sm px-8 py-2">
                  IT Supoort PT.SSRDI.
                </p>
                <div className="flex text-white justify-between space-x-4 items-center">
                  <FaYoutube className="w-[20px]" /> <FaGithub /> <FaWhatsapp />
                </div>
              </motion.div>
            </div>
            <div className="grid grid-cols-1 lg:mt-20 mt-20 lg:grid-cols-4 gap-16 lg:gap-8 justify-center items-center">
              <motion.div
                variants={fadeIn("right", 0.3)}
                initial="hidden"
                whileInView={"show"}
                viewport={{ once: false, amount: 0.1 }}
                className="bg-[#1578b6] lg:h-[320px] h-[250px] rounded-3xl shadow-md shadow-gray-800 p-2 flex flex-col justify-center items-center"
              >
                <img
                  className="lg:mt-[-150px] border-4 border-black mt-[-90px] shadow-md shadow-gray-900 rounded-full w-[120px] lg:w-[150px]"
                  src={Bahri}
                  alt=""
                />

                <h1 className="mt-2 tracking-wider uppercase text-2xl font-extrabold text-white border-b-2 border-gray-100">
                  Bahri
                </h1>

                <p className="mt-4 text-[#E69519] font-primary font-extrabold text-center leading-6">
                  It Support
                </p>
                <p className="text-white text-center text-sm px-8 py-2">
                  It Support PT. SSRDI
                </p>
                <div className="flex text-white justify-between space-x-4 items-center">
                  <FaYoutube className="w-[20px]" /> <FaGithub /> <FaWhatsapp />
                </div>
              </motion.div>
              <motion.div
                variants={fadeIn("down", 0.3)}
                initial="hidden"
                whileInView={"show"}
                viewport={{ once: false, amount: 0.3 }}
                className="bg-[#1578b6] h-[320px] rounded-3xl shadow-md shadow-gray-800 p-2 flex flex-col justify-center items-center"
              >
                <img
                  className="lg:mt-[-150px] border-4 border-black mt-[-150px] shadow-md shadow-gray-900 rounded-full w-[120px] lg:w-[150px]"
                  src={Rahmat}
                  alt=""
                />

                <h1 className="mt-2 tracking-wider uppercase text-2xl font-extrabold text-white border-b-2 border-gray-100">
                  Rahmat Hidayat
                </h1>

                <p className="mt-4 text-[#E69519] font-primary font-extrabold text-center leading-6">
                  Android Developer
                </p>
                <p className="text-white text-center text-sm px-8 py-2">
                  Web & Android Developer PT.SSRDI.
                </p>
                <div className="flex text-white justify-between space-x-4 items-center">
                  <FaYoutube className="w-[20px]" /> <FaGithub /> <FaWhatsapp />
                </div>
              </motion.div>
              <motion.div
                variants={fadeIn("down", 0.3)}
                initial="hidden"
                whileInView={"show"}
                viewport={{ once: false, amount: 0.1 }}
                className="bg-[#1578b6] lg:h-[320px] h-[250px] rounded-3xl shadow-md shadow-gray-800 p-2 flex flex-col justify-center items-center"
              >
                <img
                  className="lg:mt-[-150px] border-4 border-black mt-[-90px] shadow-md shadow-gray-900 rounded-full w-[120px] lg:w-[150px]"
                  src={Munajat}
                  alt=""
                />

                <h1 className="mt-2 tracking-wider uppercase text-2xl font-extrabold text-white border-b-2 border-gray-100">
                  Munajat
                </h1>

                <p className="mt-4 text-[#E69519] font-primary font-extrabold text-center leading-6">
                  Web Developer
                </p>
                <p className="text-white text-center text-sm px-8 py-2">
                  Web Developer PT. SSRDI.
                </p>
                <div className="flex text-white justify-between space-x-4 items-center">
                  <FaYoutube className="w-[20px]" /> <FaGithub /> <FaWhatsapp />
                </div>
              </motion.div>
              <motion.div
                variants={fadeIn("left", 0.3)}
                initial="hidden"
                whileInView={"show"}
                viewport={{ once: false, amount: 0.1 }}
                className="bg-[#1578b6] lg:h-[320px] h-[250px] rounded-3xl shadow-md shadow-gray-800 p-2 flex flex-col justify-center items-center"
              >
                <img
                  className="lg:mt-[-150px] border-4 border-black mt-[-90px] shadow-md shadow-gray-900 rounded-full w-[120px] lg:w-[150px]"
                  src={Wendi}
                  alt=""
                />

                <h1 className="mt-2 tracking-wider uppercase text-2xl font-extrabold text-white border-b-2 border-gray-100">
                  Wendi
                </h1>

                <p className="mt-4 text-[#E69519] font-primary font-extrabold text-center leading-6">
                  Tehnisi
                </p>
                <p className="text-white text-center text-sm px-8 py-2">
                  Tehnisi PT. SSRDI
                </p>
                <div className="flex text-white justify-between space-x-4 items-center">
                  <FaYoutube className="w-[20px]" /> <FaGithub /> <FaWhatsapp />
                </div>
              </motion.div>
            </div>
            <div className="relative md:w-[75%] mx-auto justify-center items-center">
              <div className="grid grid-cols-1 lg:mt-20 mt-20 lg:grid-cols-3 gap-16 lg:gap-8 justify-center items-center">
                <motion.div
                  variants={fadeIn("right", 0.3)}
                  initial="hidden"
                  whileInView={"show"}
                  viewport={{ once: false, amount: 0.2 }}
                  className="bg-[#1578b6] h-[320px] rounded-3xl shadow-md shadow-gray-800 p-2 flex flex-col justify-center items-center"
                >
                  <img
                    className="lg:mt-[-150px] border-4 border-black mt-[-150px] shadow-md shadow-gray-900 rounded-full w-[120px] lg:w-[150px]"
                    src={Stanly}
                    alt=""
                  />

                  <h1 className="mt-2 tracking-wider uppercase text-2xl font-extrabold text-white border-b-2 border-gray-100">
                    Stanly
                  </h1>

                  <p className="mt-4 text-[#E69519] font-primary font-extrabold text-center leading-6">
                    Tehnisi
                  </p>
                  <p className="text-white text-center text-sm px-8 py-2">
                    Tehnisi PT.SSRDI.
                  </p>
                  <div className="flex text-white justify-between space-x-4 items-center">
                    <FaYoutube className="w-[20px]" /> <FaGithub />{" "}
                    <FaWhatsapp />
                  </div>
                </motion.div>
                <motion.div
                  variants={fadeIn("down", 0.3)}
                  initial="hidden"
                  whileInView={"show"}
                  viewport={{ once: false, amount: 0.3 }}
                  className="bg-[#1578b6] h-[320px] rounded-3xl shadow-md shadow-gray-800 p-2 flex flex-col justify-center items-center"
                >
                  <img
                    className="lg:mt-[-150px] border-4 border-black mt-[-150px] shadow-md shadow-gray-900 rounded-full w-[120px] lg:w-[150px]"
                    src={Caman}
                    alt=""
                  />

                  <h1 className="mt-2 tracking-wider uppercase text-2xl font-extrabold text-white border-b-2 border-gray-100">
                    Caman
                  </h1>

                  <p className="mt-4 text-[#E69519] font-primary font-extrabold text-center leading-6">
                    Tehnisi
                  </p>
                  <p className="text-white text-center text-sm px-8 py-2">
                    Tehnisi PT.SSRDI.
                  </p>
                  <div className="flex text-white justify-between space-x-4 items-center">
                    <FaYoutube className="w-[20px]" /> <FaGithub />{" "}
                    <FaWhatsapp />
                  </div>
                </motion.div>
                <motion.div
                  variants={fadeIn("left", 0.3)}
                  initial="hidden"
                  whileInView={"show"}
                  viewport={{ once: false, amount: 0.1 }}
                  className="bg-[#1578b6] lg:h-[320px] h-[250px] rounded-3xl shadow-md shadow-gray-800 p-2 flex flex-col justify-center items-center"
                >
                  <img
                    className="lg:mt-[-150px] border-4 border-black mt-[-90px] shadow-md shadow-gray-900 rounded-full w-[120px] lg:w-[150px]"
                    src={AdiHidayat}
                    alt=""
                  />

                  <h1 className="mt-2 tracking-wider uppercase text-2xl font-extrabold text-white border-b-2 border-gray-100">
                    Adi Hidayat
                  </h1>

                  <p className="mt-4 text-[#E69519] font-primary font-extrabold text-center leading-6">
                    Tehnisi
                  </p>
                  <p className="text-white text-center text-sm px-8 py-2">
                    Tehnisi PT.SSRDI.
                  </p>
                  <div className="flex text-white justify-between space-x-4 items-center">
                    <FaYoutube className="w-[20px]" /> <FaGithub />{" "}
                    <FaWhatsapp />
                  </div>
                </motion.div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default TimSupport;
