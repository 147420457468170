import React from "react";
import { motion } from "framer-motion";
import { fadeIn } from "../variants";
import APJII from "../assets/mitra/apjii-nusra-biru.png";
import HSP from "../assets/mitra/hsp_inpos.png";
import Prancis from "../assets/client/prancis.png";
import Hfz from "../assets/client/hfz.png";
import Puskesmas from "../assets/client/puskesmas.png";
import Rionet from "../assets/client/rionet.png";
import DiaraHotel from "../assets/client/diarahotel.png";
import Hafidznet from "../assets/client/hafidznet.png";

const Partner = () => {
  return (
    <>
      <section className="w-full flex items-center " id="partner">
        <div className="container mx-auto py-6 px-2">
          <div className="flex flex-col justify-center items-center">
            <h1 className="text-3xl items-center font-extrabold text-[#E69519] border-b-2 border-gray-900">
              Our Partner
            </h1>
          </div>
          <div className="pt-8 grid lg:grid-cols-2 grid-cols-1 gap-10">
            <motion.div
              variants={fadeIn("up", 0.3)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: false, amount: 0.3 }}
              className="rounded-3xl flex p-6 flex-col justify-start items-center"
            >
              <img src={APJII} alt="apjii" />
            </motion.div>
            <motion.div
              variants={fadeIn("up", 0.3)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: false, amount: 0.3 }}
              className="rounded-3xl flex p-6 flex-col justify-start items-center"
            >
              <img src={HSP} alt="hsp" />
            </motion.div>
          </div>
          <div className="flex flex-col justify-center mt-10 items-center">
            <h1 className="text-3xl items-center font-extrabold text-[#E69519] border-b-2 border-gray-900">
              Our Client
            </h1>
          </div>
        </div>
      </section>
      <section>
        <div className="w-full bg-[#0468A6]">
          <div className="container mx-auto grid md:gap-6 gap-2 grid-cols-3 md:grid-cols-6 justify-center items-center ">
            <div>
              <img className="p-2" src={Prancis} alt="" />
            </div>
            <div>
              <img className="p-2" src={Hafidznet} alt="" />
            </div>
            <div>
              <img className="p-2" src={Hfz} alt="" />
            </div>
            <div>
              <img className="p-2" src={Rionet} alt="" />
            </div>
            <div>
              <img className="p-2" src={Puskesmas} alt="" />
            </div>
            <div>
              <img className="p-2" src={DiaraHotel} alt="" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Partner;
