import React from 'react';
import ListTestimoni from './ListTestimoni';

const Testimoni = () => {
  return (
    <>
  <div className="h-[50vh] lg:h-screen relative overflow-hidden" id="testimoni">
  <section className="bg-testimoni h-[50vh] lg:h-screen bg-cover lg:bg-cover lg:bg-center" style={{backgroundAttachment: 'fixed'}}>
    <div className="absolute inset-0  bg-black bg-opacity-70"></div>
    <div className="container mx-auto">
    <div className="absolute inset-0 flex flex-col items-center justify-center">
             <div className="mt-2 font-primary font-bold lg:font-extrabold text-2xl lg:text-[60px] text-center text-white">
              <h1 className="py-2 lg:py-14">Apa Kata Mereka</h1>
              
            </div>
            <div className='container text-sm lg:text-xl px-8 lg:px-32 text-center text-white'>
              <p>
              Sebagian dari Client yang Menggunakan Produk Kami
              </p>
            </div>
          </div>
    </div>
  </section>
</div>
<ListTestimoni />
</>
  );
};

export default Testimoni;
