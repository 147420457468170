import React from "react";
import Sutadi from '../assets/team/dhani-dhanu-920.jpg'
import Direktur from '../assets/team/rochmat-920.jpg'
import HRD from '../assets/team/samsudin-920.jpg'
import { motion } from "framer-motion";
import { fadeIn} from "../variants";
import { FaGithub, FaYoutube, FaWhatsapp } from "react-icons/fa";

const TimManajemen = () => {
  return (
    <>
      <div className="w-full">
        <div className="bg-[#0468A6]">
          <div className="container mx-auto pb-10 px-4">
            <div className="flex flex-col justify-center items-center py-10">
              <h1 className="text-[#E69519] text-2xl font-bold">Tim Kami </h1>
              <h1 className="text-white text-center text-2xl mb-5 lg:mb-10 lg:text-4xl font-bold">
                Top Management <br/>PT.SSR Digital Informatika{" "}
              </h1>
            </div>
            <div className="grid gri-cols-1 lg:grid-cols-3 gap-12 lg:gap-12 justify-center items-center">
              <motion.div
                variants={fadeIn("right", 0.3)}
                initial="hidden"
                whileInView={"show"}
                viewport={{ once: false, amount: 0.1 }}
                className="bg-[#1578b6] lg:h-[320px] h-[250px] rounded-3xl shadow-md shadow-gray-800 p-2 flex flex-col justify-center items-center"
              >
                <img
                  className="lg:mt-[-150px] border-4 border-black mt-[-90px] shadow-md shadow-gray-900 rounded-full w-[120px] lg:w-[150px]"
                  src={Sutadi}
                  alt=""
                />

                <h1 className="mt-2 tracking-wider uppercase text-2xl font-extrabold text-white border-b-2 border-gray-100">
                  Sutadi
                </h1>

                <p className="mt-4 text-[#E69519] font-primary font-extrabold text-center leading-6">
                  Wakil Direktur 
                </p>
                <p className="text-white text-center text-sm px-8 py-2">
                  Wakil Direktur - IT Development - Network Enginering.
                </p>
                <div className="flex text-white justify-between space-x-4 items-center">
                  <FaYoutube className="w-[20px]"/> <FaGithub/> <FaWhatsapp/>
                </div>
              </motion.div>
              <motion.div
                variants={fadeIn("down", 0.3)}
                initial="hidden"
                whileInView={"show"}
                viewport={{ once: false, amount: 0.2 }}
                className="bg-[#1578b6] h-[320px] rounded-3xl shadow-md shadow-gray-800 p-2 flex flex-col justify-center items-center"
              >
                <img
                  className="lg:mt-[-150px] border-4 border-black mt-[-150px] shadow-md shadow-gray-900 rounded-full w-[120px] lg:w-[150px]"
                  src={Direktur}
                  alt=""
                />

                <h1 className="mt-2 tracking-wider uppercase text-2xl font-extrabold text-white border-b-2 border-gray-100">
                  Rochmat Hidayat
                </h1>

                <p className="mt-4 text-[#E69519] font-primary font-extrabold text-center leading-6">
                  Direktur 
                </p>
                <p className="text-white text-center text-sm px-8 py-2">
                  Direktur PT.SSRDI.
                </p>
                <div className="flex text-white justify-between space-x-4 items-center">
                  <FaYoutube className="w-[20px]"/> <FaGithub/> <FaWhatsapp/>
                </div>
              </motion.div>
              <motion.div
                variants={fadeIn("left", 0.3)}
                initial="hidden"
                whileInView={"show"}
                viewport={{ once: false, amount: 0.3 }}
                className="bg-[#1578b6] h-[320px] rounded-3xl shadow-md shadow-gray-800 p-2 flex flex-col justify-center items-center"
              >
                <img
                  className="lg:mt-[-150px] border-4 border-black mt-[-150px] shadow-md shadow-gray-900 rounded-full w-[120px] lg:w-[150px]"
                  src={HRD}
                  alt=""
                />

                <h1 className="mt-2 tracking-wider uppercase text-2xl font-extrabold text-white border-b-2 border-gray-100">
                  Samsudin
                </h1>

                <p className="mt-4 text-[#E69519] font-primary font-extrabold text-center leading-6">
                  Komisaris 
                </p>
                <p className="text-white text-center text-sm px-8 py-2">
                  HRD Management PT.SSRDI.
                </p>
                <div className="flex text-white justify-between space-x-4 items-center">
                  <FaYoutube className="w-[20px]"/> <FaGithub/> <FaWhatsapp/>
                </div>
              </motion.div>
              
              
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TimManajemen;
