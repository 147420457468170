import React from "react";
import Prancis from "../assets/client/prancis.png";
import Hfz from "../assets/client/hfz.png";
import Puskesmas from '../assets/client/puskesmas.png'
import Rionet from '../assets/client/rionet.png'
import DiaraHotel from "../assets/client/diarahotel.png";
import Hafidznet from "../assets/client/hafidznet.png";
import { motion } from "framer-motion";
import { fadeIn } from "../variants";
import { FaGithub, FaYoutube, FaWhatsapp } from "react-icons/fa";

const ListTestimoni = () => {
  return (
    <>
      <div className="w-full">
        <div className="bg-[#0468A6]">
          <div className="container mx-auto pb-10 px-4">
            <div className="flex flex-col justify-center items-center py-10">
              <h1 className="text-gradient text-2xl font-bold">Client Feedback</h1>
              <h1 className="text-white mt-4 tracking-wider text-center text-2xl mb-10 lg:mb-14 lg:text-4xl font-bold">
                Testimonial 
              </h1>
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-3 sm:gap-20 gap-20 lg:gap-12 justify-center items-center">
              <motion.div
                variants={fadeIn("up", 0.3)}
                initial="hidden"
                whileInView={"show"}
                viewport={{ once: false, amount: 0.1 }}
                className="bg-[#1578b6] lg:h-[320px] h-[250px] rounded-3xl shadow-md shadow-gray-800 p-2 flex flex-col justify-center items-center"
              >
                <img
                  className="lg:mt-[-120px] sm:mt-[-125px] p-2 border-4 border-white mt-[-90px] bg-black shadow-md shadow-gray-900 rounded-full w-[120px] lg:w-[150px]"
                  src={DiaraHotel}
                  alt=""
                />

                <h1 className="mt-3 tracking-wider uppercase text-2xl font-extrabold text-[#E69519] font-primary border-b-2 border-gray-100">
                  Diara Hotel
                </h1>
                
                <p className="text-white text-center text-[1rem] mt-2 px-2 lg:px-4">
                <blockquote class="p-2 my-2 lg:p-4 lg:my-4">
                    <p>"PT. SSRDI membantu manajemen pembagian akses internet di Hotel Kami"</p>
                </blockquote>
                  
                </p>
                <div className="flex text-white mt-4 justify-between space-x-4 items-center">
                  <FaYoutube className="w-[20px]"/> <FaGithub/> <FaWhatsapp/>
                </div>
              </motion.div>
              <motion.div
                variants={fadeIn("up", 0.3)}
                initial="hidden"
                whileInView={"show"}
                viewport={{ once: false, amount: 0.2 }}
                className="bg-[#1578b6] h-[320px] rounded-3xl shadow-md shadow-gray-800 p-2 flex flex-col justify-center items-center"
              >
                <img
                  className="lg:mt-[-120px] p-2 border-4 bg-black border-white  mt-[-150px] shadow-md shadow-gray-900 rounded-full w-[120px] lg:w-[150px]"
                  src={Hafidznet}
                  alt=""
                />

                <h1 className="mt-3 tracking-wider uppercase text-2xl font-extrabold text-[#E69519] font-primary border-b-2 border-gray-100">
                  Hafiz Net
                </h1>
                
                <p className="text-white text-center text-[1rem] mt-2 px-2 lg:px-4">
                <blockquote class="p-2 my-2 lg:p-4 lg:my-4">
                    <p>"My Radboox sangat Membantu kami mengelola manajemen Pelanggan Wifi."</p>
                </blockquote>
                  
                </p>
                <div className="flex text-white justify-between mt-4 space-x-4 items-center">
                  <FaYoutube className="w-[20px]"/> <FaGithub/> <FaWhatsapp/>
                </div>
              </motion.div>
              <motion.div
                variants={fadeIn("up", 0.3)}
                initial="hidden"
                whileInView={"show"}
                viewport={{ once: false, amount: 0.3 }}
                className="bg-[#1578b6] h-[320px] rounded-3xl shadow-md shadow-gray-800 p-2 flex flex-col justify-center items-center"
              >
                <img
                  className="lg:mt-[-120px] bg-black p-2 border-4 border-white  mt-[-150px] shadow-md shadow-gray-900 rounded-full w-[120px] lg:w-[150px]"
                  src={Prancis}
                  alt=""
                />

                <h1 className="mt-3 tracking-wider uppercase text-2xl font-extrabold text-[#E69519] font-primary border-b-2 border-gray-100">
                  Prancis Wifi
                </h1>
                
                <p className="text-white text-center text-[1rem] mt-2 px-2 lg:px-4">
                <blockquote class="p-2 my-2 lg:p-4 lg:my-4">
                    <p>"Maju Terus untuk PT. SSRDI semoga makin berkah dan bermanfaat buat orang banyak"</p>
                </blockquote>
                  
                </p>
                <div className="flex text-white justify-between mt-4 space-x-4 items-center">
                  <FaYoutube className="w-[20px]"/> <FaGithub/> <FaWhatsapp/>
                </div>
              </motion.div>
              
            </div>
          </div>
          <div className="container mt-20 lg:mt-16 mx-auto pb-10 px-4">           
            <div className="grid grid-cols-1 lg:grid-cols-3 sm:gap-20 gap-20 lg:gap-12 justify-center items-center">
              <motion.div
                variants={fadeIn("up", 0.3)}
                initial="hidden"
                whileInView={"show"}
                viewport={{ once: false, amount: 0.1 }}
                className="bg-[#1578b6] lg:h-[320px] h-[250px] rounded-3xl shadow-md shadow-gray-800 p-2 flex flex-col justify-center items-center"
              >
                <img
                  className="lg:mt-[-120px] bg-white sm:mt-[-125px] p-2 border-4 border-black mt-[-90px] shadow-md shadow-gray-900 rounded-full w-[120px] lg:w-[150px]"
                  src={Hfz}
                  alt=""
                />

                <h1 className="mt-3 tracking-wider uppercase text-2xl font-extrabold text-[#E69519] font-primary border-b-2 border-gray-100">
                  HFZ
                </h1>
                
                <p className="text-white text-center text-[1rem] mt-2 px-2 lg:px-4">
                <blockquote class="p-2 my-2 lg:p-4 lg:my-4">
                    <p>"SSRDI sangat Membantu kami mengelola manajemen Pelanggan Wifi."</p>
                </blockquote>
                  
                </p>
                <div className="flex text-white mt-4 justify-between space-x-4 items-center">
                  <FaYoutube className="w-[20px]"/> <FaGithub/> <FaWhatsapp/>
                </div>
              </motion.div>
              <motion.div
                variants={fadeIn("up", 0.3)}
                initial="hidden"
                whileInView={"show"}
                viewport={{ once: false, amount: 0.2 }}
                className="bg-[#1578b6] h-[320px] rounded-3xl shadow-md shadow-gray-800 p-2 flex flex-col justify-center items-center"
              >
                <img
                  className="lg:mt-[-120px] bg-black p-2 border-4 border-white  mt-[-150px] shadow-md shadow-gray-900 rounded-full w-[120px] lg:w-[150px]"
                  src={Puskesmas}
                  alt=""
                />

                <h1 className="mt-3 tracking-wider uppercase text-2xl font-extrabold text-[#E69519] font-primary border-b-2 border-gray-100">
                  Puskesmas
                </h1>
                
                <p className="text-white text-center text-[1rem] mt-2 px-2 lg:px-4">
                <blockquote class="p-2 my-2 lg:p-4 lg:my-4">
                    <p>"Terima Kasih PT. SSRDI Pelayanan Maintenance Sangat Memuaskan"</p>
                </blockquote>
                  
                </p>
                <div className="flex text-white justify-between mt-4 space-x-4 items-center">
                  <FaYoutube className="w-[20px]"/> <FaGithub/> <FaWhatsapp/>
                </div>
              </motion.div>
              <motion.div
                variants={fadeIn("up", 0.3)}
                initial="hidden"
                whileInView={"show"}
                viewport={{ once: false, amount: 0.3 }}
                className="bg-[#1578b6] h-[320px] rounded-3xl shadow-md shadow-gray-800 p-2 flex flex-col justify-center items-center"
              >
                <img
                  className="lg:mt-[-120px] bg-white p-2 border-4 border-black  mt-[-150px] shadow-md shadow-gray-900 rounded-full w-[120px] lg:w-[150px]"
                  src={Rionet}
                  alt=""
                />

                <h1 className="mt-3 tracking-wider uppercase text-2xl font-extrabold text-[#E69519] font-primary border-b-2 border-gray-100">
                  Rionet
                </h1>
                
                <p className="text-white text-center text-[1rem] mt-2 px-2 lg:px-4">
                <blockquote class="p-2 my-2 lg:p-4 lg:my-4">
                    <p>"My Radboox sangat Membantu kami mengelola manajemen Pelanggan Wifi."</p>
                </blockquote>
                  
                </p>
                <div className="flex text-white justify-between mt-4 space-x-4 items-center">
                  <FaYoutube className="w-[20px]"/> <FaGithub/> <FaWhatsapp/>
                </div>
              </motion.div>
              
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ListTestimoni;
